import {Divider, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {red} from "@mui/material/colors";
import {categoryEmitter} from "./CategoryTree";
import {EventEmitter} from "nate-react-api-helpers";
import React from "react";

export const customPrep = "Custom"
export const customPrepWithBlankFrame = "Custom w/ Blank Frame"
export const noPrep = "No Prep"
export const noPrepNeeded = "**No prep needed**"
export const contHingePrep = "Cont Hinge Reinforcing"
export const followCloserRule = "**Follow closer rule**"
export const locksetASAPrep = "ASA"
export const dbStrikePrep = "DB Strike"
export const dbPrep = "DB Prep"
export const blankPrep = "BLANK"
export const electricStrikePrep = "Electric Strike"

export enum PrepKey {
    None = "none",
    AllCustom = "all-custom",

    Hinge45Std = "hinge-45-std",
    Hinge5Std = "hinge-5-std",
    Hinge45HW = "hinge-45-hw",
    Hinge5HW = "hinge-5-hw",
    HingeCont = "hinge-cont",
    Lockset161ASA = "lockset-161-asa",
    Deadbolt = "deadbolt",
    ExitDeviceRPReinforce = "exit-device-rp-reinforce",
    ExitTrimCustomWithBlank = "exit-trim-custom-with-blank",
    CloserReinforcing = "closer-reinforcing",
    BoltFBSTrike = "bolt-fb-strike",
    ElectricStrike = "electric-strike",
    ElectricStrikeASA = "electric-strike-asa",
    WiringHarnessRaceway = "wiring-harness-raceway",
    SurfaceAutoOpCloseReinf = "surface-auto-op-close-reinf",
}

export const noPrepOpt = {
    singleFramePrep: noPrepNeeded,
    singleFramePrepIsCustom: false,
    singleActiveDoorPrep: noPrepNeeded,
    singleActiveDoorPrepIsCustom: false,
    pairFramePrep: noPrepNeeded,
    pairFramePrepIsCustom: false,
    pairActiveDoorPrep: noPrepNeeded,
    pairActiveDoorPrepIsCustom: false,
    pairInactiveDoorPrep: noPrepNeeded,
    pairInactiveDoorPrepIsCustom: false,
}

type OptionGroup = {
    category: string;

    singleFramePreps: string[];
    singleFramePrepsCustom?: string[];
    pairFramePreps: string[];
    pairFramePrepsCustom?: string[];
    singleActiveDoorPreps: string[];
    singleActiveDoorPrepsCustom?: string[];
    pairActiveDoorPreps: string[];
    pairActiveDoorPrepsCustom?: string[];
    pairInactiveDoorPreps: string[];
    pairInactiveDoorPrepsCustom?: string[];
}

export type PrepOption = {
    needsTemplate?: boolean;

    singleFramePrep: string;
    singleFramePrepIsCustom: boolean;
    singleActiveDoorPrep: string;
    singleActiveDoorPrepIsCustom: boolean;

    pairFramePrep: string;
    pairFramePrepIsCustom: boolean;
    pairActiveDoorPrep: string;
    pairActiveDoorPrepIsCustom: boolean;
    pairInactiveDoorPrep: string;
    pairInactiveDoorPrepIsCustom: boolean;
}

const hinge45Std = "4.5\" STD"
const hinge5Std = "5\" STD"
const hinge45HW = "4.5\" HW"
const hinge5HW = "5\" HW"

export function isNormalHingePrep(value: string, key: string) {
    switch(key) {
        case PrepKey.Hinge45Std:
        case PrepKey.Hinge5Std:
        case PrepKey.Hinge45HW:
        case PrepKey.Hinge5HW:
            return true;
        default:
            break;
    }

    switch(value) {
        case hinge45Std:
        case hinge5Std:
        case hinge45HW:
        case hinge5HW:
            return true;
        default:
            return false;
    }
}

const options: OptionGroup[] = [
    {
        category: "Hinges",

        singleFramePreps: [hinge45Std],
        singleFramePrepsCustom: [hinge5Std, hinge45HW, hinge5HW, contHingePrep],

        pairFramePreps: [hinge45Std],
        pairFramePrepsCustom: [hinge5Std, hinge45HW, hinge5HW, contHingePrep],

        singleActiveDoorPreps: [hinge45Std],
        singleActiveDoorPrepsCustom: [hinge5Std, hinge45HW, hinge5HW],

        pairActiveDoorPreps: [hinge45Std],
        pairActiveDoorPrepsCustom: [hinge5Std, hinge45HW, hinge5HW],

        pairInactiveDoorPreps: [hinge45Std],
        pairInactiveDoorPrepsCustom: [hinge5Std, hinge45HW, hinge5HW],
    },{
        category: "Locksets",
        singleFramePreps: [locksetASAPrep],
        pairFramePreps: [],
        singleActiveDoorPreps: ["161"],
        pairActiveDoorPreps: ["161"],
        pairInactiveDoorPreps: [],
        pairInactiveDoorPrepsCustom: [locksetASAPrep],
    },{
        category: "Deadbolts",
        singleFramePreps: [],
        singleFramePrepsCustom: [dbStrikePrep],
        pairFramePreps: [],
        singleActiveDoorPreps: [],
        singleActiveDoorPrepsCustom: [dbPrep],
        pairActiveDoorPreps: [],
        pairActiveDoorPrepsCustom: [dbPrep],
        pairInactiveDoorPreps: [],
        pairInactiveDoorPrepsCustom: [dbStrikePrep],
    },{
        category: "Exit Devices",
        singleFramePreps: [blankPrep],
        pairFramePreps: [blankPrep],
        singleActiveDoorPreps: ["RP REINF"],
        pairActiveDoorPreps: ["RP REINF"],
        pairInactiveDoorPreps: ["RP REINF"],
    },{
        category: "Exit Trims",
        singleFramePreps: [blankPrep],
        pairFramePreps: [blankPrep],
        singleActiveDoorPreps: [],
        pairActiveDoorPreps: [],
        pairInactiveDoorPreps: [],
    },{
        category: "Closers",
        singleFramePreps: [followCloserRule],
        pairFramePreps: [followCloserRule],
        singleActiveDoorPreps: [],
        pairActiveDoorPreps: [],
        pairInactiveDoorPreps: [],
    },{
        category: "Bolts",
        singleFramePreps: [],
        singleFramePrepsCustom: ["FB Strike"],
        pairFramePreps: [],
        pairFramePrepsCustom: ["FB Strike"],
        singleActiveDoorPreps: [],
        pairActiveDoorPreps: [],
        pairInactiveDoorPreps: [],
        pairInactiveDoorPrepsCustom: ["FB Prep"],
    },{
        category: "Electric Strikes",
        singleFramePreps: [locksetASAPrep],
        singleFramePrepsCustom: [electricStrikePrep],
        pairFramePreps: [],
        singleActiveDoorPreps: [],
        pairActiveDoorPreps: [],
        pairInactiveDoorPreps: [locksetASAPrep],
        pairInactiveDoorPrepsCustom: [locksetASAPrep],
    },{
        category: "Wiring Harness",
        singleFramePreps: [],
        pairFramePreps: [],
        singleActiveDoorPreps: [],
        pairActiveDoorPreps: [],
        pairInactiveDoorPreps: [],
        pairInactiveDoorPrepsCustom: ["Raceway"],
    },{
        category: "Surface Automatic Operator",
        singleFramePreps: [followCloserRule],
        pairFramePreps: [followCloserRule],
        singleActiveDoorPreps: [],
        pairActiveDoorPreps: [],
        pairInactiveDoorPreps: [],
    }
]

export function PrepMenuOptions(props: {
    value: PrepOption;
    category: number | null
    onChange(value: Partial<PrepOption>): void;
}) {
    const categories = EventEmitter.reactValue(categoryEmitter);
    if(!categories) return <MenuItem key="loading">Loading...</MenuItem>

    if(props.category === null) {
        return <div key="error" style={{color: red["800"]}}>Please choose a category first</div>
    }

    let cat = categories.find(v => v.id === props.category)
    while(cat && cat.parentCategory) {
        cat = categories.find(v => v.id === cat?.parentCategory)
    }

    if(!cat) {
        return <div key="error" style={{color: red["800"]}}>Internal error: can't find category</div>
    }

    const group = options.find(v => v.category === cat?.name) || defaultGroup;

    return (
        <>
            <Typography variant="body2">HW Preps (Single)</Typography>
            <div style={{height: 8}}/>
            <PrepMenu id="single-frame" name="Frame"
                      value={props.value.singleFramePrep}
                      editKey="singleFramePrep" editKeyCustom="singleFramePrepIsCustom"
                      groupKey="singleFramePreps" groupCustomKey="singleFramePrepsCustom"
                      group={group} onChange={props.onChange}/>
            <div style={{height: 8}}/>
            <PrepMenu id="single-active" name="Door"
                      value={props.value.singleActiveDoorPrep}
                      editKey="singleActiveDoorPrep" editKeyCustom="singleActiveDoorPrepIsCustom"
                      groupKey="singleActiveDoorPreps" groupCustomKey="singleActiveDoorPrepsCustom"
                      group={group} onChange={props.onChange}/>

            <div style={{height: 18}}/>

            <Typography variant="body2">HW Preps (Pair)</Typography>
            <div style={{height: 8}}/>
            <PrepMenu id="pair-frame" name="Frame"
                      value={props.value.pairFramePrep}
                      editKey="pairFramePrep" editKeyCustom="pairFramePrepIsCustom"
                      groupKey="pairFramePreps" groupCustomKey="pairFramePrepsCustom"
                      group={group} onChange={props.onChange}/>
            <div style={{height: 8}}/>
            <PrepMenu id="pair-active-door" name="Active"
                      value={props.value.pairActiveDoorPrep}
                      editKey="pairActiveDoorPrep" editKeyCustom="pairActiveDoorPrepIsCustom"
                      groupKey="pairActiveDoorPreps" groupCustomKey="pairActiveDoorPrepsCustom"
                      group={group} onChange={props.onChange}/>
            <div style={{height: 8}}/>
            <PrepMenu id="pair-inactive-door" name="Inactive"
                      value={props.value.pairInactiveDoorPrep}
                      editKey="pairInactiveDoorPrep" editKeyCustom="pairInactiveDoorPrepIsCustom"
                      groupKey="pairInactiveDoorPreps" groupCustomKey="pairInactiveDoorPrepsCustom"
                      group={group} onChange={props.onChange}/>
        </>
    );
}

const defaultGroup: OptionGroup = {
    category: "Unknown",
    singleFramePreps: [],
    singleFramePrepsCustom: [],
    pairFramePreps: [],
    pairFramePrepsCustom: [],
    singleActiveDoorPreps: [],
    singleActiveDoorPrepsCustom: [],
    pairActiveDoorPreps: [],
    pairActiveDoorPrepsCustom: [],
    pairInactiveDoorPreps: [],
    pairInactiveDoorPrepsCustom: [],
}

function PrepMenu(props: {
    id: string;
    name: string;
    value: string;
    editKey: keyof PrepOption
    editKeyCustom: keyof PrepOption
    groupKey: keyof Omit<OptionGroup, "category">;
    groupCustomKey: keyof Omit<OptionGroup, "category">;
    group: OptionGroup;
    onChange(value: Partial<PrepOption>): void;
}) {
    const group = props.group;

    const customOpts = group[props.groupCustomKey] || [];
    const stdOpts = group[props.groupKey] || [];
    const options = [...(stdOpts), ...(customOpts)];
    const value = fixValue(props.value);
    const valueIsOpt = options.includes(value);
    const hasValueOpt = options.includes(value) || value === noPrep || value === customPrep;
    const selectValue = valueIsOpt ? options.indexOf(value) : hasValueOpt ? value : "unexpected";
    console.log("sel", props.id, selectValue, value, props.value, hasValueOpt, valueIsOpt)

    return (
        <FormControl fullWidth>
            <InputLabel id={props.id}>{props.name}</InputLabel>
            <Select labelId={props.id} size="small" fullWidth label={props.name}
                    value={selectValue}
                    onChange={e => {
                        const value = e.target.value as any as string;
                        if(value === customPrep) {
                            props.onChange({
                                [props.editKey]: "**As per template**",
                                [props.editKeyCustom]: true,
                            })
                        } else if(value === noPrep) {
                            props.onChange({
                                [props.editKey]: noPrepNeeded,
                                [props.editKeyCustom]: false,
                            })
                        } else if(value === "unexpected") {
                            return
                        } else {
                            const v = parseInt(value)
                            if(!group) return;
                            const opt = options[v]

                            props.onChange({
                                [props.editKey]: opt,
                                [props.editKeyCustom]: customOpts.includes(opt),
                            })
                        }
                    }}>
                {!hasValueOpt && <MenuItem key="missing-value" value={"unexpected"}>{value} (Error: unexpected value)</MenuItem>}
                <MenuItem key="no-prep" value={noPrep}>No Prep</MenuItem>
                {group && options.length > 0 && <Divider />}
                {options.map((o, index) => <MenuItem key={group.category + "." + o} value={index.toString()}>
                    {o}
                </MenuItem>)}
                {group && <Divider />}
                <MenuItem value={customPrep}>Custom</MenuItem>
            </Select>
        </FormControl>
    )
}

function fixValue(value: string) {
    if(value === "") return noPrep;
    if(value === noPrepNeeded) return noPrep;
    if(value === "**As per template**") return customPrep;
    return value;
}